// src/hooks/useEvents.ts
import { useQuery } from '@tanstack/react-query';
import { FeatureCollection, Point } from 'geojson';

interface EventVenue {
  id: number;
  name: string;
  location: {
    lat: number;
    lon: number;
  };
}

interface Event {
  id: number;
  name: string;
  location: {
    lat: number;
    lon: number;
  };
  start: string;
  ticket_url: string;
  image_url: string;
  venue: EventVenue;
}

interface EventsPage {
  events: Event[];
  event_count: number;
  page: number;
  page_size: number;
  num_pages: number;
}

interface EventsResponse {
  events_page: EventsPage;
  venues: FeatureCollection<Point>;
}

const fetchEvents = async (): Promise<EventsResponse> => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const response = await fetch(`${apiUrl}/api/events/results?upcoming_within=P30D`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const useEvents = () => {
  return useQuery<EventsResponse>({
    queryKey: ['events'],
    queryFn: fetchEvents,
  });
};
