import React, { useEffect, useState } from 'react';
import './ResultsTiles.css'; 
import { useEvents } from './hooks/useResults';


const ResultsTiles: React.FC = () => {
  const { data, error, isLoading } = useEvents();
  if (isLoading) return <div className="results-tiles">Loading...</div>;
  if (error) return <div className="results-tiles">Error: {error.message}</div>;

  const events_page = data?.events_page;

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const optionsDate: Intl.DateTimeFormatOptions = { 
      weekday: 'short', 
      month: 'short', 
      day: '2-digit' 
    };
    const optionsTime: Intl.DateTimeFormatOptions = { 
      hour: 'numeric', 
      minute: '2-digit', 
      hour12: true 
    };
    const formattedDate = date.toLocaleDateString('en-US', optionsDate);
    const formattedTime = date.toLocaleTimeString('en-US', optionsTime);
    return `${formattedDate} • ${formattedTime}`;
  };

  return (
    <div className="results-tiles">
      {events_page?.events.map((event) => (
        <div key={event.id} className="event-tile">
          {event.image_url && (
            <img src={event.image_url} alt={event.name} className="event-image" />
          )}
          <h2>{event.name}</h2>
          <h3>{event.venue.name}</h3>
          <p>{formatDate(new Date(event.start).toLocaleString())}</p>
          <a href={event.ticket_url} target="_blank" rel="noopener noreferrer">
            Get Tickets
          </a>
        </div>
      ))}
    </div>
  );
};

export default ResultsTiles;
