import './App.css';
import { MapClusterComponentDebug } from './EventMapClusterComponentDebug';
import ResultsTiles from './ResultsTiles';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Fanflame</h1>
      </header>
      <div className="App-body">
        <ResultsTiles />
        <div className="map-container">
          <MapClusterComponentDebug />
        </div>
      </div>
    </div>
  );
}

export default App;